.dates_block {
  border-bottom: 1px solid #e5e7eb;
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 4px 24px;
}
.collapse {
  :global {
    .ant-collapse-header-text {
      font-weight: 600;
    }
  }
}
