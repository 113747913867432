@import '@styles/variables';
.modal-image-preview {
  :global {
    .slick-slide {
      > div {
        > div {
          display: inline-flex !important;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .slick-arrow.slick-prev, .slick-arrow.slick-next {
      opacity: 1;
      color: rgba(0, 0, 0, 0.88) !important;
      height: 100%;
      background: #fff;
      width: 64px;
      &.slick-disabled {
        &:after {
          opacity: 0;
        }
      }
      @media (max-width: $device-tablet-max) {
        width: 24px;
      }
      &:after {
        top: 50%;
        margin-top: -8px;
      }
    }
    .slick-arrow.slick-next {
      right: -24px;
      &:after {
        right: 50%;
        left: auto;
        margin-right: -4px;
      }
    }
    .slick-arrow.slick-prev, {
      left: -24px;
      &:after {
        left: 50%;
        right: auto;
        margin-left: -4px;
      }
    }
    .ant-modal-close {
      width: 22px;
      height: 22px;
      top: 20px;
      right: 24px;
    }
    .ant-carousel .slick-dots li button {
      background-color: rgba(0, 0, 0, 0.88);
    }
  }
}
