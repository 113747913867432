@import '@styles/variables';
.page {
  padding: 16px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.block {
  background: #fff;
  padding: 32px 32px 64px;
  border-radius: 8px;
  width: 100%;
  @media (max-width: $device-mobile-max) {
    padding: 16px 16px 32px;
  }
}
.header {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  .content {
    width: 100%;
    padding: 0 16px 0 0;
    max-width: 1400px;
    margin: 0 auto;
  }
}
.footer {
  width: 100%;
  .content {
    width: 100%;
    padding: 16px 16px 32px;
    max-width: 1400px;
    margin: 0 auto;
  }
}
.menu {
  width: 100%;
  border-bottom: medium none;
}
.link {
  white-space: nowrap;
}

.not_print {
  @media print {
    display: none;
  }
}
