@import '@styles/variables';
.info {
  align-items: initial;
  width: 100%;
  @media (max-width: $device-mobile-max) {
    flex-direction: column;
  }
}
.list {
  @media (max-width: $device-tablet-max) {
    flex-direction: column;
    align-items: initial;
  }
}
.divider {
  @media (max-width: $device-tablet-max) {
    display: none;
  }
}
.image {
  object-fit: cover;
  width: 300px !important;
  height: 300px !important;
  border-radius: 50%;
  @media (max-width: $device-tablet-max) {
    width: 100px !important;
    height: 100px !important;
  }
}