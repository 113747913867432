@import '@styles/variables';
.badge {
  border: 1px solid #e5e7eb;
  background: #fafafa;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
}

.collapse {
  :global {
    .ant-collapse-header-text {
      font-weight: 600;
    }
  }
}

.color_gray {
  color: #777;
}

.ribbon {
  margin-right: -32px;
  @media (max-width: $device-mobile-max) {
    margin-right: -16px;
  }
}

.dates_block {
  border-bottom: 1px solid #e5e7eb;
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 4px 24px;
}

.list {
  padding-left: 24px !important;
  position: relative;
  &:before {
    display: inline-block;
    content: "–";
    position: absolute;
    left: 0;
    top: 7px;
  }
}
